import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export type SignupFields = {
  email?: string
  hash?: string
  passCode?: number
  captchaToken?: string
  approvalMessage?: string
}

export type SignupStep =
  | 'rules'
  | 'signup'
  | 'passCode'
  | 'captcha'
  | 'approval'

export type AuthState = {
  signupStep: SignupStep
  signupFields: SignupFields | null
  token: string | null
  isPending: boolean
  isAuthenticated: boolean
}
export const AUTH_COOKIE_NAME = 'auth_token'

const initialState: AuthState = {
  signupStep: 'rules',
  token: null,
  signupFields: null,
  isPending: false,
  isAuthenticated: false,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => initialState,
    setSignupStep: (state, action: PayloadAction<SignupStep>) => {
      state.signupStep = action.payload
    },
    updateSignupFields: (state, action: PayloadAction<SignupFields | null>) => {
      state.signupFields = { ...state.signupFields, ...action.payload }
    },
    resetSignupFields: (state) => {
      state.signupFields = null
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
    login: (state, action: PayloadAction<string>) => {
      state.isAuthenticated = true
      state.token = action.payload
      state.isPending = false
    },
  },
  selectors: {
    selectToken: (state) => state.token,
    selectSignupStep: (state) => state.signupStep,
    selectSignupFields: (state) => state.signupFields,
    selectPending: (state) => state.isPending,
    selectIsAuthenticated: (state) => state.isAuthenticated,
  },
})
