import { api } from 'openapi/api'
import { handleBasicResponse } from 'openapi'
import { getErrorMsg } from 'error-kit/helpers/getErrorMsg'

import { toast } from 'react-toastify'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { userSlice } from './slices/userSlice'

export const userApi = api
  .enhanceEndpoints({
    endpoints: {
      deleteApiV1UsersByUserIdDelete: {
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      getApiV1AccountsProfile: {
        providesTags: ['Secure', 'Account'],
        async onQueryStarted(_, { dispatch, queryFulfilled }) {
          dispatch(userSlice.actions.setPending(true))
          try {
            const res = await handleBasicResponse(queryFulfilled)
            if (res) {
              dispatch(userSlice.actions.setData(res.data))
            }
            dispatch(userSlice.actions.setSuccess(true))
          } catch (e) {
            dispatch(userSlice.actions.setSuccess(false))
            dispatch(userSlice.actions.setIsError(true))
            dispatch(userSlice.actions.setError(e))
          } finally {
            dispatch(userSlice.actions.setPending(false))
          }
        },
      },
      putApiV1UsersByUserId: {
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled
            toast.success('User updated successfully')
          } catch (e) {
            toast.error(getErrorMsg(e as FetchBaseQueryError))
          }
        },
      },
      getApiV1Users: {
        providesTags: ['Secure'],
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      postApiV1Users: {
        invalidatesTags: ['Account'],
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled
            toast.success('User created successfully')
          } catch (e) {
            const error = e as FetchBaseQueryError
            toast.error(
              error?.status === 409
                ? 'Username already exists'
                : getErrorMsg(error),
            )
          }
        },
      },
      getApiV1UsersFollowers: {
        providesTags: ['Follow'],
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      getApiV1UsersFollowees: {
        providesTags: ['Follow'],
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      getApiV1UsersByUserIdFollowing: {
        providesTags: ['Follow'],
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      postApiV1UsersFollowByUsernameAccept: {
        invalidatesTags: ['Follow'],
        async onQueryStarted(user, { queryFulfilled }) {
          try {
            await queryFulfilled

            toast.success(`Accepted follow request from ${user.username}`)
          } catch (e) {
            toast.error(getErrorMsg(e as FetchBaseQueryError))
          }
        },
      },
      postApiV1UsersFollowByUsernameReject: {
        invalidatesTags: ['Follow', 'Secure'],
        async onQueryStarted(user, { queryFulfilled }) {
          try {
            await queryFulfilled
            toast.success(`Rejected follow request from ${user.username}`)
          } catch (e) {
            toast.error(getErrorMsg(e as FetchBaseQueryError))
          }
        },
      },
      postApiV1UsersFollowByUsername: {
        invalidatesTags: ['Follow', 'Secure'],
        async onQueryStarted(user, { queryFulfilled }) {
          try {
            await queryFulfilled
            toast.success(`Follow request sent to ${user.username}`)
          } catch (e) {
            toast.error(getErrorMsg(e as FetchBaseQueryError))
          }
        },
      },
      postApiV1UsersFollowByUsernameUndo: {
        invalidatesTags: ['Follow', 'Secure'],
        async onQueryStarted(user, { queryFulfilled }) {
          try {
            await queryFulfilled
            toast.success(`You unfollowed ${user.username}`)
          } catch (e) {
            toast.error(getErrorMsg(e as FetchBaseQueryError))
          }
        },
      },
      getApiV1Block: {
        providesTags: ['Follow'],
      },
      postApiV1BlockByUserId: {
        invalidatesTags: ['Follow'],
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      deleteApiV1BlockByUserId: {
        invalidatesTags: ['Follow'],
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },

      deleteApiV1AccountsDelete: {},
      postApiV1MuteByUserId: {
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      deleteApiV1MuteByUserId: {
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
      getApiV1Mute: {
        async onQueryStarted(_, { queryFulfilled }) {
          await handleBasicResponse(queryFulfilled)
        },
      },
    },
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getInteractionUrl: build.query<Response, string>({
        query: (url: string) => ({
          url,
          headers: { Accept: 'application/activity+json' },
        }),
        transformResponse: (response: string) => {
          return typeof response === 'string'
            ? (JSON.parse(response) as Response)
            : (response as Response)
        },
      }),
    }),
  })

export const {
  useGetInteractionUrlQuery,
  usePostApiV1UsersMutation,
  useDeleteApiV1AccountsDeleteMutation,
  useGetApiV1AccountsProfileQuery,
  usePutApiV1UsersByUserIdMutation,
  useGetApiV1UsersQuery,
  useDeleteApiV1UsersByUserIdDeleteMutation,
  usePutApiV1UsersLocationMutation,
  usePostApiV1MuteByUserIdMutation,
  useDeleteApiV1MuteByUserIdMutation,
  useGetApiV1MuteQuery,
  usePutApiV1UsersTzMutation,
  usePutApiV1UsersBirthdayMutation,
} = userApi
