import { api } from 'openapi/api'
import { handleBasicResponse } from 'openapi'

import { instanceInfoSlice } from './instanceSlice'

export const instanceInfoApi = api.enhanceEndpoints({
  endpoints: {
    getApiV1Instance: {
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        dispatch(instanceInfoSlice.actions.setPending(true))
        try {
          const response = await handleBasicResponse(queryFulfilled)
          response && dispatch(instanceInfoSlice.actions.setInfo(response.data))
        } catch (e) {
          dispatch(instanceInfoSlice.actions.setError(e))
        } finally {
          dispatch(instanceInfoSlice.actions.setPending(false))
        }
      },
    },
  },
})

export const { useGetApiV1InstanceQuery } = instanceInfoApi
