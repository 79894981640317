import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { Notification, NotificationStatus } from 'openapi'
import { rootReducer } from 'store'
import { NotificationItem } from './types'

export type NotificationsSliceState = {
  data: NotificationItem[] | null
  count: {
    total?: number
    unread?: number
  }
  isPending: boolean
  error?: unknown
}

type MarkNotificationProps = {
  id: string
  status: NotificationStatus
}

const initialState: NotificationsSliceState = {
  data: [],
  count: {},
  isPending: false,
}

const selectState = (state: NotificationsSliceState) => state
const selectError = (state: NotificationsSliceState) => state.error
const selectIsPending = (state: NotificationsSliceState) => state.isPending
const selectTotalCount = (state: NotificationsSliceState) => state.count?.total
const selectUnreadCount = (state: NotificationsSliceState) =>
  state.count?.unread

const selectNotifications = createSelector(selectState, (state) => state.data)
const selectUnreadNotifications = createSelector(selectNotifications, (list) =>
  list?.filter((n) => n.status === 'UNREAD'),
)

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload
    },
    setError: (state, action: PayloadAction<unknown>) => {
      state.error = action.payload
    },
    setNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.data = [...(state.data || []), ...action.payload]
    },
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.count.unread = (state.count.unread || 0) + 1

      if (state.data) {
        state.data = [action.payload, ...(state.data || [])]
      }
    },
    markNotification: (state, action: PayloadAction<MarkNotificationProps>) => {
      if (!state.data) return

      state.data = state.data.map((n) =>
        n.id === action.payload.id
          ? { ...n, status: action.payload.status }
          : n,
      )
    },
    updateNotification: (state, action: PayloadAction<NotificationItem>) => {
      if (!state.data) return

      const updatedNotification = action.payload

      state.data = state.data?.map((n) =>
        n.id === updatedNotification.id ? { ...n, ...updatedNotification } : n,
      )
    },
    setTotalCount: (state, action: PayloadAction<number>) => {
      state.count.total = action.payload
    },
    setUnreadCount: (state, action: PayloadAction<number>) => {
      state.count.unread = action.payload
    },
    readAll: (state) => {
      if (state.data) {
        state.data = state.data?.map((n) => ({ ...n, status: 'READ' }))
      }
    },
    resetState: () => initialState,
  },
  selectors: {
    selectError,
    selectIsPending,
    selectTotalCount,
    selectUnreadCount,
    selectNotifications,
    selectUnreadNotifications,
  },
}).injectInto(rootReducer)
